<template>
  <v-layout v-if="isVerified" row wrap align-center justify-center style="height: 91.5vh;">
    <v-flex xs12 md6 mb-5 mx-3>
      <v-sheet class="pa-4 text-xs-center dropShadow">
        <v-layout row wrap justify-center pb-4>
          <img
            src="https://res.cloudinary.com/starupgeek-law/image/upload/v1591679969/assets/kynxfoxrdnm77ekv89ab.png"
            :width="resize()"
            :style="{'margin-top: -24px;': !$vuetify.breakpoint.mdAndDown}"
          />
        </v-layout>
        
        <div v-if="$store.getters['auth/loggedIn']">
          <div v-if="!$vuetify.breakpoint.mdAndDown">
            <p class="title font-weight-medium mb-2">Your Email has been verified!</p>
            <span>You can now access all the features of LawyerKo.</span>
          </div>

          <div v-else>
            <p class="caption title font-weight-medium mb-1">Your Email has been verified!</p>
            <span class="caption">You can now access all the features of LawyerKo.</span>
          </div>
        </div>
        <div v-else>
          <div v-if="!$vuetify.breakpoint.mdAndDown">
            <p class="title font-weight-medium mb-2">Your Email has been verified!</p>
            <span>Login now to proceed.</span>
          </div>

          <div v-else>
            <p class="caption title font-weight-medium mb-1">Your Email has been verified!</p>
            <span class="caption">Login now to proceed.</span>
          </div>
        </div>
        <v-flex xs12 class="pa-0 mt-3 text-xs-center">
          <v-btn :large="!$vuetify.breakpoint.mdAndDown" text class="primary text-xs-center" @click="proceed()">Proceed</v-btn>
        </v-flex>
      </v-sheet>
    </v-flex>
  </v-layout>
</template>

<script>
import VerificationService from '@/services/VerificationService'

export default {
  name: 'Verification',
  data () {
    return {
      isVerified: false
    }
  },
  methods: {
    resize () {
      if (!this.$vuetify.breakpoint.mdAndDown) {
        return "25%"
      } else {
        return "50%"
      }
    },
    proceed () {
      if (this.$store.getters['auth/loggedIn']) {
        if (this.$store.getters['user/user'].user.isLawyer) {
          this.$router.push('/jobs')
        } else {
          this.$router.push('/post-problem')
        }
      } else {
        this.$router.push('/login')
      }
    },
    getVerified () {
      VerificationService.getVerified({
        userId: this.$route.params.userId,
        verificationToken: this.$route.params.verificationToken
      })
        .then(() => {
          this.$store.dispatch('user/verifyUserEmail', true)
          this.isVerified = true
        })
        .catch(() => {
          this.$router.go(-1)
        })
    }
  },
  created () {
    this.getVerified()
  }
}
</script>
